<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0">
    <PageHeader
      header-text="Forms"
      regular-button-text="New Form"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="$router.push({ name: 'CreateForm' })"
    />

    <SearchFieldRowWithPagination
      search-field-placeholder="Search for forms"
      :current-page-number="formsPageNumber"
      :current-page-size="formPageSize"
      :count-total="formsTotal"
      :page-sizes="[25, 50, 100]"
      :init-search-string="searchString"
      @next-page="getFormsNextPage"
      @prev-page="getFormsPrevPage"
      @change-page-size="formsChangePageSize"
      @search="onSearch"
    />

    <FormTable
      :forms="forms"
      @action="handleAction($event)"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import FormTable from "@/views/Channels/components/FormTable";
import { hasError } from "@/mixins/has_error";

export default {
  name: "FormView",
  metaInfo: {
    title: 'Forms'
  },
  components: {
    SearchFieldRowWithPagination,
    PageHeader,
    FormTable,
  },
  mixins: [ hasError ],
  data() {
    return {
      dialog: false,
      forms: [],
      formsTotal: 0,
      formsPageNumber: 1,
      formPageSize: 25,
      searchString: '',
    };
  },
  created() {
    this.getAvailableForms(this.$route.query.keyword);
  },
  methods: {
    async handleAction(action) {
      if (action.event.value === "delete") {
        await this.$rest.forms.put_resource(action.item.id, {
          ...action,
          isArchived: true,
        });
        await this.getAvailableForms();
      } else if (action.event.value === "edit") {
        await this.$router.push({
          name: "EditForm",
          params: {
            id: action.item.id,
          },
        });
      }
    },
    async onSearch(searchString) {
      this.searchString = searchString;
      await this.getAvailableForms(searchString);
    },
    async formsChangePageSize(size) {
      this.formPageSize = size;
      this.formsPageNumber = 1;
      await this.getAvailableForms();
    },
    async getFormsNextPage() {
      this.formsPageNumber += 1;
      await this.getAvailableForms();
    },
    async getFormsPrevPage() {
      this.formsPageNumber -= 1;
      await this.getAvailableForms();
    },
    async getAvailableForms(searchString) {
      if (searchString) {
        this.searchString = searchString;
      } else {
        this.searchString = "";
      }

      const resp = await this.$rest.forms.get_collection({
        limit: this.formPageSize,
        page: this.formsPageNumber,
        name: this.searchString,
        sort: ['createdAt:desc'],
        isArchived: false,
        groups: ["tag_creative:get"],
      });

      this.forms = resp.data.items;
      this.formsTotal = resp.data.totalCount;
    },
  },
};
</script>
